/* eslint-disable no-async-promise-executor */
import axios from '@/api/axios'

const marketBalanceService = {
	listMarketBalances(payload) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await axios({
					method: 'GET',
					params: payload,
					url: '/market-balances/detail',
				})

				resolve({
					data: response.data,
					totalCount: parseInt(response.headers['x-total-count']),
					totalPagesCount: parseInt(response.headers['x-total-pages-count']),
				})
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	getMyMarketBalance() {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'GET',
					url: '/market-balances/present',
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
}

export default marketBalanceService
